<template>
  <b-card>
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">
          Today's Bills
        </h3>
        <b-button
          variant="link"
          class="ml-2 p-0"
          @click="showFilters = !showFilters"
        >
          <feather-icon
            icon="FilterIcon"
            color="primary"
            size="25"
            class="mt-25"
          />
        </b-button>
      </div>
    </template>
    <bill-filters
      v-show="showFilters"
      v-model="filters"
    />
    <div
      class="text-right mb-2"
    >
      <b-button
        variant="outline-primary"
        @click="handleRefresh"
      >
        Refresh
      </b-button>
    </div>
    <bills-list
      ref="billsList"
      :filters="parsedFilter"
    />
  </b-card>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import dateMixin from '@/mixins/dateMixin'
import BillsList from '../../components/PartyLedger/BillsList.vue'
import BillFilters from '../../components/TodayBill/BillFilters.vue'

export default {
  components: {
    BCard,
    BillsList,
    BillFilters,
    BButton,
  },
  mixins: [dateMixin],
  data() {
    return {
      showFilters: false,
      filters: {
        bill_no: null,
        date: `${this.formatedDate(new Date(), 'YYYY-MM-DD')} to ${this.formatedDate(new Date(), 'YYYY-MM-DD')}`,
        party: null,
        isTodayList: true,
      },
    }
  },
  computed: {
    parsedFilter() {
      const { date, marg_orders, ...restFilters } = this.filters
      const [fromDate, toDate] = date ? date.split(' to ') : []

      const parsedDate = fromDate && toDate
        ? {
          from_date: fromDate,
          to_date: toDate,
        }
        : {
          from_date: null,
          to_date: null,
        }

      const updatedPlacedBy = []
      if (restFilters.created_by && restFilters.created_by.length > 0) {
        // If created_by is an array, spread its values
        if (Array.isArray(restFilters.created_by)) {
          updatedPlacedBy.push(...restFilters.created_by)
        } else {
          // If it's a single value, just push it
          updatedPlacedBy.push(restFilters.created_by)
        }
      }

      // Add salesman values if they exist
      if (restFilters.salesman) {
        // If salesman is an array, spread its values
        if (Array.isArray(restFilters.salesman)) {
          updatedPlacedBy.push(...restFilters.salesman)
        } else {
          // If it's a single value, just push it
          updatedPlacedBy.push(restFilters.salesman)
        }
      } delete restFilters.salesman
      return {
        ...restFilters,
        ...parsedDate,
        marg_orders,
        created_by: updatedPlacedBy,
      }
    },
  },
  watch: {
    'filters.marg_orders': {
      handler(newValue) {
        if (newValue) {
          this.filters = {
            ...this.filters,
            created_by: '',
            salesman: '',
          }
        }
      },
      // immediate: true, // Optional: if you want it to run on component creation
    },
  },
  methods: {
    handleRefresh() {
    // Access the billsList component through the ref and call its fetchItems method
      if (this.$refs.billsList) {
        this.$refs.billsList.fetchItems()
      }
    },
  },
}
</script>
