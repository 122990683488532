<template>
  <div>
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group label="Bill Number">
          <b-form-input
            v-model="billNumber"
            type="text"
            placeholder="Search Bill Number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Areas"
        >
          <custom-v-select
            v-model="filters.area"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="areaOptions"
            label="text"
            placeholder="Select Areas"
            multiple
            :disabled="loading"
            :close-on-select="false"
            :reduce="data => data.id"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group label="Buyers">
          <custom-v-select
            v-model="filters.party"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="buyerOptions"
            label="text"
            placeholder="Select Buyers"
            multiple
            :disabled="loading"
            :close-on-select="false"
            :reduce="data => data.id"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group label="Status">
          <custom-v-select
            v-model="filters.delivery_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            label="text"
            placeholder="Select Status"
            :close-on-select="false"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Date"
        >
          <flat-picker
            v-model="filters.date"
            placeholder="Select Date Range"
            :config="{ mode: 'range'}"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Confirmed By">
          <custom-v-select
            v-model="filters.confirmed_by"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="margUsers"
            label="name"
            placeholder="Select Confirmed By"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :reduce="(data => data.name)"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Picked By">
          <custom-v-select
            v-model="filters.picked_by"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="usersList"
            label="fullname"
            placeholder="Select Picked By"
            multiple
            :close-on-select="false"
            :reduce="(data => data.id)"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Delivered By">
          <custom-v-select
            v-model="filters.delivered_by"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="usersList"
            label="fullname"
            placeholder="Select Delivered By"
            multiple
            :close-on-select="false"
            :reduce="(data => data.id)"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Item Name">
          <custom-v-select
            v-model="filters.products"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productOptions"
            label="text"
            placeholder="Select Item Name"
            multiple
            :close-on-select="false"
            :reduce="data => data.value"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex flex-column border-primary mb-1 rounded"
      >
        <!-- Common Label -->
        <div class="d-flex align-items-center justify-content-between my-1 fw-medium">
          <span>Placed By</span>
          <b-form-checkbox v-model="filters.marg_orders">
            Marg Orders
          </b-form-checkbox>
        </div>

        <!-- Inputs side by side -->
        <div
          class="d-flex mb-1"
          style="column-gap: 2rem;"
        >
          <!-- Retailer Filter -->
          <b-form-group class="w-50 mb-0">
            <custom-v-select
              v-model="filters.created_by"
              :options="placedByOptions"
              label="fullname"
              placeholder="Select Retailers"
              multiple
              :disabled="filters.marg_orders"
              :close-on-select="false"
              :reduce="data => data.id"
              :deselect-from-dropdown="true"
            />
          </b-form-group>

          <!-- User Filter -->
          <b-form-group class="w-50 mb-0">
            <custom-v-select
              v-model="filters.salesman"
              :options="usersList"
              label="fullname"
              placeholder="Select Placed by"
              multiple
              :disabled="filters.marg_orders"
              :close-on-select="false"
              :reduce="data => data.id"
              :deselect-from-dropdown="true"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col md="3">
        <b-form-group label="Delivered Mode">
          <custom-v-select
            v-model="filters.delivery_mode"
            :options="deliveryModeOptions"
            label="text"
            placeholder="Select Delivery Mode"
            multiple
            :close-on-select="false"
            :reduce="data => data.id"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BRow, BCol, BFormInput, BAlert,
  BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import { debounce } from 'lodash'
import { deliveryModeOptions } from '@/utils/consts'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BAlert,
    CustomVSelect,
    BFormGroup,
    FlatPicker,
    BFormCheckbox,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      statusOptions: ['CONFIRMED', 'PACKED', 'PICKED', 'DELIVERED'],
      areaOptions: [],
      buyerOptions: [],
      placedByOptions: [],
      salesmanOptions: [],
      margUsers: [],
      usersList: [],
      productOptions: [],
      placedByUsers: [],
      deliveryModeOptions,
    }
  },
  computed: {
    filters() {
      return this.$props.value
    },
    billNumber: {
      get() {
        return this.filters.bill_no
      },
      set(newVal) {
        this.updateBillNo(newVal)
      },
    },
  },
  mounted() {
    this.fetchDeliveryOptions()
  },
  methods: {
    updateBillNo: debounce(function updateBillNo(billNo) {
      this.$emit('input', {
        ...this.filters,
        bill_no: billNo,
      })
    }, 500),
    async fetchDeliveryOptions() {
      try {
        this.loading = true
        const { data: buyersRes } = await axios.get('/accounts/buyers_options')
        this.buyerOptions = buyersRes.data

        const { data: areas } = await axios.get('/accounts/areas_options')
        this.areaOptions = areas.data

        const { data: margUsers } = await axios.get('/accounts/marg_users')
        this.margUsers = margUsers

        const { data: salesman } = await axios.get('/accounts/salesmans_options')
        this.salesmanOptions = salesman.data

        const { data: retailer } = await axios.get('/delivery/users_list?retailers_only=true')
        this.placedByOptions = retailer
        const placedById = this.placedByOptions.map(i => i.id)
        const { data: usersList } = await axios.get('/delivery/users_list')
        this.usersList = usersList.filter(user => !placedById.includes(user.id))

        const { data: products } = await axios.get('/item_options')
        const updatedProduct = products.data.map(item => {
          const updatedTextAry = item.text.split(' ')
          let finalText = ''
          if (updatedTextAry.length >= 0) {
            finalText = updatedTextAry.slice(0, -1).join(' ')
          } else {
            finalText = updatedTextAry.join(' ')
          }
          return { id: item.id, text: finalText, value: finalText }
        })
        this.productOptions = updatedProduct
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Failed to fetch filter options!'
      } finally {
        this.loading = false
      }
    },
  },

}
</script>
